import React from "react"
import PropTypes from "prop-types"

import Link from "../../navigation/Link"

import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

import { Container, Box, Button, Typography, makeStyles } from "@material-ui/core"

import constants from "../../theme/constants"
import colors from "../../theme/colors"

const useStyles = makeStyles(theme => ({
  root: {
    background: constants.hero.background,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  imageOverlay: {
    background: "rgba(0,0,0,0.4)",
  },
  content: {
    minHeight: constants.hero.minHeight,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    paddingTop: "2rem",
    paddingBottom: "2rem",
  },
  heading: {
    color: colors.base.white,
    textTransform: "uppercase",
    maxWidth: "500px",
    marginBottom: "0.5em",
  },
  lead: {
    color: colors.base.white,
    maxWidth: "600px",
    marginBottom: "2em",
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  button: {
    color: colors.base.white,
  },
}))

const Highlight = ({ heading, lead, backgroundImageFluid, linkText, link }) => {
  const classes = useStyles()

  const highlightContent = (
    <Container className={classes.content}>
      {heading && (
        <Typography className={classes.heading} variant="h2" component="h2">
          {heading}
        </Typography>
      )}
      {lead && (
        <Typography className={classes.lead} variant="body1" component="p">
          {lead}
        </Typography>
      )}
      <Link className={classes.link} to={link} text={linkText}>
        <Button
          className={classes.button}
          variant="outlined"
          color="secondary"
          disableRipple={true}
          disableFocusRipple={true}
        >
          {linkText}
        </Button>
      </Link>
    </Container>
  )

  const convimage = getImage(backgroundImageFluid)
  const bgImage = convertToBgImage(convimage)

  return backgroundImageFluid ? (
    <BackgroundImage
      Tag="div"
      className={classes.root}
      {...bgImage}
      backgroundColor={colors.grey[2]}
    >
      <Box className={heading || lead ? classes.imageOverlay : ""}>
        {highlightContent}
      </Box>
    </BackgroundImage>
  ) : (
    <Box className={classes.root}>{highlightContent}</Box>
  )
}

Highlight.propTypes = {
  heading: PropTypes.string,
  lead: PropTypes.string,
  backgroundImageFluid: PropTypes.object,
  linkText: PropTypes.string,
  link: PropTypes.string,
}

Highlight.defaultProps = {
  linkText: "Katso lisää",
  link: "https://www.newsec.fi",
}

export default Highlight
